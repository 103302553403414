import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { faUniversity } from '@fortawesome/pro-solid-svg-icons';

import {
  Button,
  createBemBlockBuilder,
  PageErrorMessage,
} from '@ebsco-ui/ebsco-ui';

import { AuthForm, AuthPageWrapper, Icon, PageSpinner } from '@app/components';
import { barcodePinFieldsDescription } from '@app/pages';
import { useAppContext, useMappingsContext } from '@app/contexts';
import { useAuthentication } from '@app/hooks';
import { sharedMessages } from '@app/translations';
import { LOGIN_METHOD, PASSWORD_TYPE, ROUTE } from '@app/constants';

import './NativeLoginPage.scss';

const cnBem = createBemBlockBuilder(['authPageWrapper', 'loginPage']);

export const NativeLoginPage: FC = () => {
  const { $t } = useIntl();
  const { isLoggedIn } = useAppContext();
  const { notification, isLoading, isSuccess, loginUser } = useAuthentication({
    method: LOGIN_METHOD.native,
    passwordFieldName: PASSWORD_TYPE.pin,
  });
  const {
    notification: ssoNotification,
    isSuccess: isSSOSuccess,
    isLoading: isSSOLoading,
    isSSOEnabled,
    isSSOError,
    loginUser: loginSSO,
  } = useAuthentication({
    method: LOGIN_METHOD.sso,
    shouldCheckSSO: true,
  });
  const {
    configurations: { isSSOLogin, isNativeLogin, configurationsQuery },
  } = useMappingsContext();

  const ssoButton = isSSOEnabled && !isNativeLogin && (
    <div className={cnBem('__row')}>
      <div className={cnBem('__divider')}>
        <span>
          {$t({
            id: 'login.or',
            defaultMessage: 'Or',
          })}
        </span>
      </div>
      <Button className={cnBem('__signInSSOButton')} onClick={loginSSO}>
        <Icon icon={faUniversity} />
        {$t({
          id: 'login.signInSSO',
          defaultMessage: 'Access through your institution',
        })}
      </Button>
    </div>
  );

  useEffect(() => {
    if (!isLoggedIn && isSSOLogin && !isSSOError) {
      loginSSO();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, isSSOLogin]);

  return (
    <>
      <Helmet>
        <title>
          {$t({
            id: 'login.documentTitle',
            defaultMessage: 'Login - EBSCO Locate',
          })}
        </title>
      </Helmet>
      {(configurationsQuery?.isLoading || (isSSOLogin && !isSSOError)) && (
        <PageSpinner />
      )}
      {configurationsQuery?.isError && (
        <div className={cnBem('__pageErrorMessage')}>
          <PageErrorMessage
            buttonText={$t(sharedMessages.refreshPage)}
            title={$t(sharedMessages.pageUnavailable)}
            text={$t(sharedMessages.searchUnavailableText)}
            titleTag="h2"
            onClick={() => configurationsQuery?.refetch()}
          />
        </div>
      )}
      {configurationsQuery?.isSuccess && (!isSSOLogin || isSSOError) && (
        <AuthPageWrapper
          title={$t(sharedMessages.welcomeBack)}
          notification={notification || ssoNotification}
        >
          <AuthForm
            fields={barcodePinFieldsDescription}
            additionalFields={ssoButton}
            passwordResetLink={
              <Link
                to={ROUTE.enterBarcode}
                className={cn('underlinedLink', cnBem('__resetPin'))}
              >
                {$t({
                  id: 'login.needNewPin',
                  defaultMessage: 'Need a new PIN?',
                })}
              </Link>
            }
            isLoading={isLoading || isSuccess || isSSOLoading || isSSOSuccess}
            shouldDisplayFields={!isSSOLogin}
            onSubmit={loginUser}
          />
        </AuthPageWrapper>
      )}
      <form id="ssoForm" aria-hidden={true} />
    </>
  );
};
