import { MessageDescriptor } from 'react-intl';
import { Dispatch, SetStateAction } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export enum ROUTE {
  root = '/',
  search = '/search',
  login = '/login',
  directLogin = '/direct-login',
  logout = '/logout',
  instanceDetails = '/instances/:id',
  staffView = '/instances/:id/staff-view',
  bookshelf = '/bookshelf',
  courseReserves = '/course-reserves',
  courseReserveDetails = '/course-reserves/:id',
  holds = '/bookshelf/holds',
  checkouts = '/bookshelf/checkouts',
  fees = '/bookshelf/fees',
  sso = '/sso-landing',
  callNumbers = '/call-numbers',
  preferences = '/my-preferences',
  notFound = '/not-found',
  resetPinRoot = '/reset-pin',
  enterNewPin = '/reset-pin/pin',
  enterBarcode = '/reset-pin/barcode',
  myDashboard = '/my-dashboard',
}

export enum FEATURE {
  bookshelf = 'BOOKSHELF',
  courseReserves = 'COURSE_RESERVES',
  staffView = 'STAFF_VIEW',
  virtualBookshelf = 'VIRTUAL_BOOKSHELF',
  novelist = 'NOVELIST',
  account = 'ACCOUNT',
  callNumbers = 'CALL_NUMBERS',
  instanceRelationship = 'INSTANCE_RELATIONSHIP',
  statusSearchFacet = 'STATUS_SEARCH_FACET',
  publicationYearFacet = 'PUBLICATION_YEAR_FACET',
  consortiaLoginFlow = 'CONSORTIA_LOGIN_FLOW',
  RTACForSearch = 'RTAC_FOR_SEARCH',
  saveInstances = 'SAVE_INSTANCES',
  LCCNSearch = 'LCCN_SEARCH',
  passthroughlink = 'PASSTHROUGH_LINK',
}

export enum USER_SESSION {
  guest = 'guestUserSession',
  patron = 'patronUserSession',
}

export const BREAKPOINT = {
  CONTAINER: {
    MINIMUM_SCREEN_SIZE: 274,
    PHONE: 382,
    TABLET_SM: 738,
    TABLET_LG: 960,
    DESKTOP_SM: 1180,
    DESKTOP_MD: 1428,
    DESKTOP_LG: 1600,
  },
  SCREEN: {
    MINIMUM_SCREEN_SIZE: 0,
    PHONE: 411,
    TABLET_SM: 767,
    TABLET_LG: 1023,
    DESKTOP_SM: 1239,
    DESKTOP_MD: 1499,
    DESKTOP_LG: 1919,
  },
};

export const SUPPORT_SITE_URL = 'https://connect.ebsco.com/s/?language=en_US';

export enum SORT_DIRECTION {
  asc = 'ascending',
  desc = 'descending',
}

export const ADVANCED_SEARCH_ERROR = {
  CODE: 'validation_error',
  TYPE: 'RequestValidationException',
};

export const BACK_TO_TOP_TRIGGER_POSITION = 300;

export const MIN_SUGGESTION_TERM_LENGTH = 3;

export const IS_TEST_ENV = process.env.NODE_ENV === 'test';

export const RECORDS_PER_PAGE = IS_TEST_ENV ? 2 : 25;

export const MODAL_PLACEMENT = IS_TEST_ENV ? 'body > div' : '#root';

export const LOGIN_METHODS = {
  SSO: 'SSO only',
  NATIVE: 'Native only',
};

export interface URLQuery {
  [key: string]: string | string[];
}

export interface SortOption {
  label: MessageDescriptor;
  sortField: string;
  sortDirection?: SORT_DIRECTION;
  icon?: IconDefinition;
}

export enum NOTIFICATION_STATUS {
  success = 'success',
  error = 'error',
  information = 'information',
}

export const FOCUSED_ELEMENT_SELECTOR = '[data-focus=focusedNode]';

export enum DefaultCoverImageTypes {
  'Books' = 'book',
  'Videos' = 'video',
  'Electronic Resources' = 'eBook',
  'Audio' = 'audio',
  'nonCatalog' = 'nonCatalog',
}

export const ENGLISH_LOCALE_KEY = 'en';

// eslint-disable-next-line @typescript-eslint/ban-types
export type PropsWithClassName<P = {}> = P & { className?: string };

export enum LOGIN_METHOD {
  native = 'native',
  direct = 'direct',
  sso = 'sso',
}

export enum PASSWORD_TYPE {
  pin = 'pin',
  password = 'password',
}

export const INVOKE_PATH_PREFIX = `/_/invoke/tenant/${process.env.REACT_APP_API_TENANT}`;

export type SetState<Type> = Dispatch<SetStateAction<Type>>;
