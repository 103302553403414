import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { AuthRoute, ConditionalRoute } from '@app/components';
import {
  DirectLoginPage,
  EnterBarcodePage,
  EnterNewPinPage,
  LoginPage,
  LogoutPage,
  MainPage,
  SSOPage,
} from '@app/pages';
import { FEATURE, ROUTE } from '@app/constants';

export const AppRoutes: FC = () => (
  <Routes>
    <Route
      path={ROUTE.login}
      element={
        <AuthRoute>
          <LoginPage />
        </AuthRoute>
      }
    />
    <Route
      path={ROUTE.directLogin}
      element={
        <AuthRoute>
          <DirectLoginPage />
        </AuthRoute>
      }
    />
    <Route
      path={ROUTE.logout}
      element={
        <ConditionalRoute flags={[FEATURE.account]}>
          <LogoutPage />
        </ConditionalRoute>
      }
    />
    <Route
      path={ROUTE.sso}
      element={
        <AuthRoute>
          <SSOPage />
        </AuthRoute>
      }
    />
    <Route
      path={ROUTE.enterNewPin}
      element={
        <AuthRoute>
          <EnterNewPinPage />
        </AuthRoute>
      }
    />
    <Route
      path={ROUTE.resetPinRoot}
      element={<Navigate replace to={ROUTE.enterBarcode} />}
    />
    <Route
      path={ROUTE.enterBarcode}
      element={
        <AuthRoute>
          <EnterBarcodePage />
        </AuthRoute>
      }
    />
    <Route path="*" element={<MainPage />} />
  </Routes>
);
